<template>
  <div>
    <div>
      <h1 class="mr-sm-4 header-tablepage">Shipping Group</h1>
    </div>
    <div class="title-tabs mt-3">Shipping Group Detail</div>

    <b-container class="no-gutters bg-white py-3">
      <b-row>
        <b-col md="6">
          <InputText
            textFloat="Name"
            placeholder="Name"
            type="text"
            name="social-name"
            v-model="form.name"
            :v="$v.form.name"
            isRequired
            :isValidate="$v.form.name.$error"
          />
        </b-col>

        <b-col cols="12">
          <div class="title-tabs">Shipping Methods</div>

          <div class="text-right">
            <b-button @click="showModal" class="my-3" variant="custom-primary">
              Add Shipping Methods
            </b-button>
          </div>
          <b-table
            responsive
            striped
            hover
            :fields="fieldsSelected"
            :items="form.shipping"
            show-empty
            :currentPage="filterSelected.page"
            :perPage="filterSelected.take"
            empty-text="No matching records found"
          >
            <template v-slot:cell(banner)="{ item }">
              <b-img
                :src="item.banner || default_image"
                class="banner-image w-100"
                @error="handleImageSrc"
              >
              </b-img>
            </template>
            <template v-slot:cell(action)="{ item }">
              <b-button
                variant="custom-link"
                @click="removeSelected(item.shipping_method_id)"
              >
                <font-awesome-icon
                  icon="trash-alt"
                  title="Delete"
                  class="main-color"
                />
              </b-button>
            </template>
            <template v-slot:table-busy>
              <div class="text-center text-black my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="ml-2">Loading...</strong>
              </div>
            </template>
          </b-table>
          <Pagination
            @handleChangeTake="onTakeSelectedChange"
            :filter="filterSelected"
            :rows="form.shipping.length"
            @pagination="(val) => (filterSelected.page = val)"
          />
          <div class="text-error" v-if="$v.form.shipping.$error">
            Please Select Methods At least 1.
          </div>
        </b-col>
      </b-row>
    </b-container>

    <b-modal v-model="modal" centered size="lg">
      <template #modal-header>Shipping Methods</template>

      <b-table
        responsive
        striped
        hover
        :fields="fields"
        :items="items"
        :busy="isBusy"
        show-empty
        empty-text="No matching records found"
      >
        <template v-slot:cell(id)="{ item }">
          <b-form-checkbox
            v-model="selected"
            :value="item.id"
            @change="onSelected(item)"
          ></b-form-checkbox>
        </template>
        <template v-slot:cell(banner)="{ item }">
          <b-img
            :src="item.banner || default_image"
            class="banner-image w-100"
            @error="handleImageSrc"
          >
          </b-img>
        </template>

        <template v-slot:table-busy>
          <div class="text-center text-black my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="ml-2">Loading...</strong>
          </div>
        </template>
      </b-table>
      <Pagination
        @handleChangeTake="onTakeChange"
        :filter="filter"
        :rows="rows"
        @pagination="pagination"
      />
      <template #modal-footer>
        <div class="content-between w-100">
          <b-button class="btn-cancel btn-width" @click="modal = false"
            >Cancel
          </b-button>
          <b-button class="submit-btn btn-width" @click="selectMethods"
            >Confirm</b-button
          >
        </div>
      </template>
    </b-modal>
    <FooterAction
      routePath="/setting/shipping/shipping-group"
      @submit="saveForm()"
    />
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import PreviewBox from "@/components/PreviewBox.vue";
export default {
  components: { PreviewBox },
  validations() {
    return {
      form: {
        name: {
          required,
        },
        shipping: {
          required,
        },
      },
    };
  },
  data() {
    return {
      form: {
        id: 0,
        name: "",
        shipping: [],
      },
      isDisable: false,
      isImageLoading: false,
      itemsSelected: [],
      fieldsSelected: [
        {
          key: "banner",
          label: "Image",
        },
        {
          key: "name",
          label: "Name",
          class: "text-center",
        },

        { key: "action", label: "Action" },
      ],
      filter: {
        search: "",
        page: 1,
        take: 5,
      },
      filterSelected: {
        search: "",
        page: 1,
        take: 5,
      },
      isBusy: false,
      rows: 0,

      fields: [
        {
          key: "id",
          label: "",
        },
        {
          key: "banner",
          label: "Image",
        },
        {
          key: "name",
          label: "Name",
          class: "text-center",
        },

        { key: "action", label: "" },
      ],
      items: [],
      modal: false,
      selected: [],
      objectSelected: [],
    };
  },
  mounted() {},

  created() {
    if (this.$route.params.id != 0) {
      this.getList();
    }
  },
  destroyed() {},
  methods: {
    async getList() {
      const response = await this.axios(
        `/setting/GetShippingGroupById/${this.$route.params.id}`
      );
      this.form = response.data.detail;
      // this.itemsSelected = response.data.detail.shipping;
    },
    async saveForm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) return;
      if (this.$route.params.id == 0) {
        this.$bus.$emit("showLoading");
        const res = await this.axios.post(
          `/setting/CreateShippingGroup`,
          this.form
        );

        if (res.data.result) {
          this.successAlert().then(
            (val) =>
              val && this.$router.push("/setting/shipping/shipping-group")
          );
        } else {
          this.errorAlert(res.data.message);
        }
        this.$bus.$emit("hideLoading");
      } else {
        this.$bus.$emit("showLoading");
        this.axios
          .put(`/setting/UpdateShippingGroup`, this.form)
          .then((res) => {
            if (res.data.result) {
              this.successAlert().then(
                (val) =>
                  val && this.$router.push("/setting/shipping/shipping-group")
              );
            } else {
              this.errorAlert(res.data.message);
            }
            this.$bus.$emit("hideLoading");
          });
      }
    },
    async showModal() {
      this.modal = true;
      await this.getMethods();
      this.selected = this.form.shipping.map((el) => el.shipping_method_id);
      this.objectSelected = JSON.parse(JSON.stringify(this.form.shipping));
    },
    async getMethods() {
      this.isBusy = true;

      const response = await this.axios.post(
        `/setting/GetShippingMethod`,
        this.filter
      );
      this.items = response.data.detail.data;
      this.rows = response.data.detail.count;

      this.isBusy = false;
    },
    onSelected(val) {
      let index = this.objectSelected.findIndex(
        (el) => el.shipping_method_id == val.id
      );

      if (index == -1)
        this.objectSelected.push({
          shipping_group_id: this.$route.params.id,
          shipping_method_id: val.id,
          banner: val.banner,
          name: val.name,
          sort_order: 0,
        });
      else this.objectSelected.splice(index, 1);
    },
    selectMethods(val) {
      this.form.shipping = JSON.parse(JSON.stringify(this.objectSelected));
      this.modal = false;
    },
    pagination(val) {
      this.filter.page = val;
      this.getMethods();
    },
    onTakeChange(val) {
      this.filter.take = val;
      this.filter.page = 1;
      this.getMethods();
    },
    onTakeSelectedChange(val) {
      this.filterSelected.page = 1;
      this.filterSelected.take = val;
    },
    removeSelected(id) {
      let index = this.form.shipping.findIndex(
        (el) => el.shipping_method_id == id
      );
      this.form.shipping.splice(index, 1);
    },
  },
};
</script>
<style>
label.title {
  color: var(--font-main-color);
  font-size: 16px;
  margin-bottom: 2px;
  font-weight: bold;
}
.f-20 {
  font-size: 20px;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #a59cfc;
}
.tabs-title-spacing {
  /* padding: 0px 15px 0 15px !important; */
  margin: 10px 0px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}
.btn-fixed {
  min-width: 150px;
}
.my-actions {
  margin: 0 2em;
}
.order-1 {
  order: 1;
}
.order-2 {
  order: 2;
}
.order-3 {
  order: 3;
}

.right-gap {
  margin-right: auto;
}
.banner-image {
  max-width: 50px;
  height: auto;
  width: 100%;
  aspect-ratio: 1;
}
</style>
